const users = {
  'ajd4827djs': {
    name: 'Davide Stefanini',
    image: '/images/davideStefanini.png',
    file: '/vcf/davideStefanini.vcf'
  },
  'ajd48s7dj5': {
    name: 'Pietro Quadrini',
    image: '/images/pietroQuadrini.png',
    file: '/vcf/pietroQuadrini.vcf'
  },
  'ajd48s7dtw': {
    name: 'Giovanni Quadrini',
    image: '/images/giovanniQuadrini.png',
    file: '/vcf/giovanniQuadrini.vcf'
  },
  'ajd48s7d9f': {
    name: 'Marco Quadrini - Tecnosan',
    image: '/images/marcoQuadrini.png',
    file: '/vcf/marcoQuadrini.vcf'
  }
}

export default users